import React, { useContext, useEffect, useState } from "react";
import PhFeedback from "./mobile/PhFeedback";
import Feedback from "./Desktop/Feedback";
import { DataContext } from "../DataContext";
import { useParams } from "react-router-dom";

const EmpProfileSection = () => {
  const { id } = useParams();
  const { data } = useContext(DataContext); // Get data from context
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    if (data && data.employees && data.departments) {
      const parsedId = parseInt(id, 10);
      const foundEmployee = data.employees.find((emp) => emp.id === parsedId);

      if (foundEmployee) {
        const foundDepartment = data.departments.find(
          (dept) => dept.id === foundEmployee.departmentId
        );

        const employeeWithDept = {
          ...foundEmployee,
          departmentName: foundDepartment ? foundDepartment.name : "Unknown",
        };

        setEmployeeData(employeeWithDept);
      }
    }
  }, [id, data]);

  if (!employeeData) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="hidden sm:hidden md:block">
        <Feedback sendingData={employeeData} />
      </div>
      <div className="block sm:block md:hidden">
        <PhFeedback sendingData={employeeData} />
      </div>
    </div>
  );
};

export default EmpProfileSection;
