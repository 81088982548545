import React, { useContext, useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../DataContext";

const Search = () => {
  const [query, setQuery] = useState("");
  const [title, setTitle] = useState("Сайн  уу 👋");

  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const { data } = useContext(DataContext);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [popUp, setPopUp] = useState(false);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const filterEmployee = (employees, query) => {
    if (!query) return employees;
    return employees.filter(
      (employee) =>
        employee.firstName.toLowerCase().includes(query.toLowerCase()) ||
        employee.lastName.toLowerCase().includes(query.toLowerCase()) ||
        employee.email.toLowerCase().includes(query.toLowerCase())
    );
  };
  const handleFocus = () => {
    setTitle("Ажлын хамтрагчдаа үнэлгээ өгцгөөе!");
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
    if (!query) { 
      setTitle("Сайн  уу 👋");
    }
  };

  useEffect(() => {
    if (data && data.employees) {
      const result = filterEmployee(data.employees, query);
      setFilteredEmployees(result);
    }
  }, [query, data]);

  if (!data) return <div>Loading...</div>;

  const filteredByDep = selectedDepartment
    ? data.employees.filter(
        (employee) => employee.departmentId === selectedDepartment
      )
    : data.employees;

  return (
    <div>
      <div className="relative">
        <div>
          <div className="bg-[#51A8FF] w-full relative h-full sm:h-full md:h-[250px] ">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src="/img/bgImg.svg"
              alt="img"
            />
            <p
              className={` font-semibold pt-8 ps-6 sm:ps-6 md:ps-40 relative z-10 ${
                title === "Сайн  уу 👋"
                  ? "mb-4 text-[32px] "
                  : "mb-0 text-[18px] "
              }`}
            >
              {title}
            </p>
            <div className="flex justify-center ">
              <div className="relative py-6">
                <input
                  className="bg-[#fff] py-2 ps-12 rounded-full w-[335px] sm:w-[335px] md:w-[600px] relative"
                  placeholder="Ажилтны нэрийг бичнэ үү."
                  type="text"
                  value={query}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={() => setTimeout(() => handleBlur(), 300)}
                />
                <div className="absolute top-16 left-2 ">
                  {query && focused && (
                    <div className="bg-white ms-2 rounded-b-xl px-4 max-h-[400px] w-[300px] sm:w-[300px] md:w-[500px] overflow-y-scroll">
                      {filteredEmployees.map((emp) => (
                        <ul
                          key={emp.id}
                          onClick={() => {
                            navigate(`/mcsi/employee/${emp.id}`);
                          }}
                          className="employee-list"
                        >
                          <li
                            className="text-xs border-b py-2 px-6 border-[#D4D4D4]   flex items-center gap-2"
                            key={emp.id}
                          >
                            <CgProfile className="w-6 h-6" />
                            {emp.firstName} {emp.lastName}
                          </li>
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
                <svg
                  className="absolute left-6 inset-y-9 size-4"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7578 11.2539H11.9678L11.6878 10.9839C12.6678 9.84391 13.2578 8.36391 13.2578 6.75391C13.2578 3.16391 10.3478 0.253906 6.75781 0.253906C3.16781 0.253906 0.257812 3.16391 0.257812 6.75391C0.257812 10.3439 3.16781 13.2539 6.75781 13.2539C8.36781 13.2539 9.84781 12.6639 10.9878 11.6839L11.2578 11.9639V12.7539L16.2578 17.7439L17.7478 16.2539L12.7578 11.2539ZM6.75781 11.2539C4.26781 11.2539 2.25781 9.24391 2.25781 6.75391C2.25781 4.26391 4.26781 2.25391 6.75781 2.25391C9.24781 2.25391 11.2578 4.26391 11.2578 6.75391C11.2578 9.24391 9.24781 11.2539 6.75781 11.2539Z"
                    fill="#222222"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="mt-12 px-4">
            <p className="text-[20px] px-0 sm:px-0 md:px-40">Хэлтсүүд</p>
            <div className="mt-4">
              <div className="flex items-center gap-6 px-4 sm:px-4 md:px-40 py-2 overflow-x-auto ">
                {data.departments.map((dept) => (
                  <div
                    key={dept.id}
                    onClick={() => setSelectedDepartment(dept.id)}
                    className={`rounded-full shadow flex items-center w-max justify-center cursor-pointer px-2 py-3 ${
                      selectedDepartment === dept.id
                        ? "bg-[#F48D7E] text-white"
                        : ""
                    }`}
                  >
                    <p className="text-sm w-max">{dept.name}</p>
                  </div>
                ))}
              </div>
              <div className="mt-8 px-4 flex flex-wrap justify-center gap-4">
                {filteredByDep.map((emp) => (
                  <div
                    key={emp.id}
                    onClick={() => {
                      navigate(`/mcsi/employee/${emp.id}`);
                    }}
                    className="flex items-start gap-5 w-full sm:w-full md:w-[40%] shadow-lg rounded-xl mb-6"
                  >
                    <div className="w-[35%] h-[94px] bg-[#ccc] rounded-xl" />
                    <div className="w-[65%]">
                      <p className="text-sm">
                        {emp.firstName} {emp.lastName}
                      </p>
                      <p className="text-[#8e9196] text-xs">
                        {emp.position}, {emp.department}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="fixed w-full bg-white bottom-0 flex justify-between">
          <div className="flex items-center justify-center w-[50%] border-b-4 border-[#F48D7E] px-4 py-2">
            <p className="text-lg font-semibold text-[#F48D7E]">Илгээх</p>
          </div>
          <div
            onClick={() => {
              setPopUp(true);
            }}
            className="flex items-center justify-center w-[50%] px-4 py-2"
          >
            <p className="text-lg font-semibold">Шалгах</p>
          </div>
        </div>
      </div>
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl p-6 w-[340px]">
            <p className="text-sm font-semibold opacity-70 mt-5 text-center">
              Танд ирсэн Feed-forward -г уншихдаа анхаарах зүйлс{" "}
            </p>
            <div className="flex justify-center my-4">
              <img src="/img/inboxIcon.svg" alt="icon" />
            </div>
            <div className="mt-4">
              <div className="flex  gap-2 items-start">
                <div className="w-max h-max">
                  <div
                    style={{
                      background:
                        "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)",
                    }}
                    className="w-3 h-3 mt-1 rounded-full "
                  />
                </div>
                <p className=" text-sm opacity-70">
                  Энэхүү ирсэн Feed-forward -г баг хамт олноос нь таны хувь
                  хүний хөгжилд туслах л зорилготой гэдгийг бүү мартаарай 💪
                </p>
              </div>
              <div className="flex gap-2  items-start">
                <div className="w-max h-max">
                  <div
                    style={{
                      background:
                        "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)",
                    }}
                    className="w-3 h-3 mt-1 rounded-full "
                  />
                </div>
                <p className=" text-sm opacity-70">
                  Хүн өөрт нь шүүмжлэл ирэхэд өөрийгөө өмөөрөх, ‘хамгаалалтын’
                  горимд шилжих талтай байдаг. Тиймээс сөрөг бодлуудаа
                  хязгаарлаад, аль болох нээлттэй хандаарай 🤗
                </p>
              </div>
              <button
                onClick={() => {
                  navigate("/mcsi/ph/inbox");
                }}
                className="w-full py-2 text-sm font-semibold text-white bg-[#2D2D2D] rounded-xl mt-4"
              >
                Ойлголоо
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
