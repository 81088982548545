import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../DataContext";
import { setCookie } from "../utils/cookie";

const Home = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setData } = useContext(DataContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCookie("userId", id, 7);

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://metacogserver.azurewebsites.net/v1/feedback/mcsi/${id}`
        );
        const result = await response.json();
        setData(result);
        console.log(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, setData]);

  if (loading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <p className="text-lg font-semibold">Loading...</p>
      </div>
    );
  }

  return (
    <div className="relative">
      <img
        className="h-screen absolute w-full"
        src="/img/background.png"
        alt="img"
      />
      <div className="h-screen flex items-center justify-center relative">
        <div className="bg-white rounded-xl py-8 px-4 w-[344px]">
          <div>
            <p className="text-sm opacity-70 font-semibold px-4">
              Feed-forward өгөх үед анхаарах зүйлс
            </p>
            <div className="my-3 flex justify-center">
              <img src="/img/home.png" alt="icon" />
            </div>
            <div className="flex items-start gap-2 px-2 mb-2">
              <img src="/img/homeToggle.png" className="mt-1.5" alt="icon" />
              <p className="text-xs opacity-70">
                Хүлээн авч байгаа хүндээ шууд хэрэгжүүлэх боломжтой зүйлс
                бичээрэй 🫰
              </p>
            </div>
            <div className="flex items-start gap-2 px-2 mb-2">
              <img src="/img/homeToggle.png" className="mt-1.5" alt="icon" />
              <p className="text-xs opacity-70">
                Ямар нэгэн утга санаагүй зүгээр л гомдоох зүйлс бичиж болохгүй
                шүү{" "}
                <span role="img" aria-label="smiley face">
                  🤗
                </span>
              </p>
            </div>
            <div className="flex items-start gap-2 px-2 mb-4">
              <img src="/img/homeToggle.png" className="mt-1.5" alt="icon" />
              <p className="text-xs opacity-70">
                Хамгийн гол нь таны өгч буй Feed-forward нь нөгөө хүнд сайжрахад
                нь туслах зорилготой юм шүү 💪
              </p>
            </div>
            <button
              onClick={() => {
                navigate(`/mcsi/search`);
              }}
              className="w-full rounded-xl py-2 text-white bg-[#2D2D2D]"
            >
              Ойлголоо
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
