import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import { getCookie } from "../../utils/cookie";
import axios from "axios";
import { FaSpinner } from "react-icons/fa6";
import "../../index.css";

const PhFeedback = ({ sendingData }) => {
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(0);
  const [modal2, setModal2] = useState(0);
  const modalRef = useRef();

  const [btn, setBtn] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  // modal
  const [startCheck, setStartCheck] = useState(1);
  const [startOnGoing, setStartOngGoing] = useState(1);
  const [placed, setPlaced] = useState("");
  const [placed2, setPlaced2] = useState("");
  const [popUp, setPopUp] = useState(false);

  //start
  const [start1, setStart1] = useState("хэрэгжүүлж эхлэх зүйл");
  const [start2, setStart2] = useState("гарах үр дүн");
  const [startWrite, setStartWrite] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);

  //continue
  const [continue1, setContinue1] = useState("үргэлжлүүлэх зүйл");
  const [continue2, setContinue2] = useState("гарах үр дүн");
  const [continueWrite, setContinueWrite] = useState("");
  const [shouldAnimate2, setShouldAnimate2] = useState(false);

  //stop
  const [stop1, setStop1] = useState("зогсоох зүйл");
  const [stop2, setStop2] = useState("гарах үр дүн");
  const [stopWrite, setStopWrite] = useState("");
  const [shouldAnimate3, setShouldAnimate3] = useState(false);

  useEffect(() => {
    if (modal2 !== 0 && (startOnGoing === 1 || startOnGoing === 2)) {
      setPlaced("");
      setPlaced2("");
      // inputRef.current.focus();
    }
  }, [modal2, startOnGoing]);

  useEffect(() => {
    if (sendingData !== undefined || sendingData !== null) {
      setData(sendingData);
    }
  }, [sendingData]);

  useEffect(() => {
    let timer;
    if (
      (start1 !== "хэрэгжүүлж эхлэх зүйл" &&
        start2 !== "гарах үр дүн" &&
        start1 !== "" &&
        start2 !== "") ||
      startWrite !== ""
    ) {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 1000);
    } else {
      setShouldAnimate(false);
    }
    return () => clearTimeout(timer);
  }, [start1, start2, startWrite]);

  useEffect(() => {
    let timer;
    if (
      (continue1 !== "үргэлжлүүлэх зүйл" &&
        continue2 !== "гарах үр дүн" &&
        continue1 !== "" &&
        continue2 !== "") ||
      continueWrite !== ""
    ) {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate2(true);
      }, 1000);
    } else {
      setShouldAnimate2(false);
    }
    return () => clearTimeout(timer);
  }, [continue1, continue2, continueWrite]);

  useEffect(() => {
    let timer;
    if (
      (stop1 !== "үргэлжлүүлэх зүйл" &&
        stop2 !== "гарах үр дүн" &&
        stop1 !== "" &&
        stop2 !== "") ||
      stopWrite !== ""
    ) {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate3(true);
      }, 1000);
    } else {
      setShouldAnimate3(false);
    }
    return () => clearTimeout(timer);
  }, [stop1, stop2, stopWrite]);

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setStartOngGoing(1);
      setModal(0);
      setModal2(0);
    }
  };

  const handleContinue = () => {
    if (startOnGoing === 1) {
      setStartOngGoing(2);
    } else {
      setModal2(0);
      setStartOngGoing(1);
    }
  };

  if (data === undefined) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Employee not found</p>
      </div>
    );
  }

  if (data === null) return <div>Loading...</div>;

  const getStartMsg = () => {
    return `та цаашид ${start1} эхэлвэл ${start2} чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗`;
  };
  const getContinueMsg = () => {
    return `та цаашид ${continue1} -ээ идэвхитэй үргэлжлүүвэл ${continue2} чадна. Keep going!✨`;
  };

  const getStopMsg = () => {
    return `та цаашид ${stop1} -ээ больж чадвал ${stop2} чадна. Амжилт!😎`;
  };

  const sendBackend = async () => {
    setLoading(true);
    try {
      let startMsg;
      let continueMsg;
      let stopMsg;

      if (
        start1 !== "хэрэгжүүлж эхлэх зүйл" &&
        start2 !== "гарах үр дүн" &&
        start1 !== "" &&
        start2 !== ""
      ) {
        startMsg = getStartMsg();
      } else if (startWrite !== "") {
        startMsg = startWrite;
      }

      if (
        continue1 !== "идэвхитэй үргэлжлүүлвэл" &&
        continue2 !== "гарах үр дүн" &&
        continue1 !== "" &&
        continue2 !== ""
      ) {
        continueMsg = getContinueMsg();
      } else if (continueWrite !== "") {
        continueMsg = continueWrite;
      }

      if (
        stop1 !== "зогсоох зүйл" &&
        stop2 !== "гарах үр дүн" &&
        stop1 !== "" &&
        stop2 !== ""
      ) {
        stopMsg = getStopMsg();
      } else if (stopWrite !== "") {
        stopMsg = stopWrite;
      }

      const id = await getCookie("userId");

      const types = [];

      if (startMsg !== undefined) {
        types.push({
          type: "START",
          text: startMsg,
        });
      }

      if (continueMsg !== undefined) {
        types.push({
          type: "CONTINUE",
          text: continueMsg,
        });
      }

      if (stopMsg !== undefined) {
        types.push({
          type: "STOP",
          text: stopMsg,
        });
      }

      const backendMsg = {
        receivingEmployeeId: sendingData.empId,
        sentEmployeeId: id,
        hide: isHidden,
        types,
      };

      const response = await axios.post(
        "https://metacogserver.azurewebsites.net/v1//feedback-answer/create",
        backendMsg,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      navigate("/mcsi/ph/sended");
      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="relative">
      <div className="relative flex items-center justify-center  ">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover rounded-b-[50px] shadow-xl"
          src="/img/bgImg.svg"
          alt="img"
        />
        {/* <img
          className="absolute top-0 w-full h-[200px] left-0"
          src="/img/Container.svg"
          alt="bg"
        /> */}
        <div className=" relative mt-10 flex flex-col items-center">
          <img
            className="h-[86px] w-[86px] "
            src="/img/pfpOnPf.svg"
            alt="pfp"
          />
          <p
            className="text-center text-[#000] text-[20px] font-semibold mt-2"
            style={{
              textShadow: "3px 3px 3px #fff", // Adjust the shadow size and color as needed
            }}
          >
            {data.firstName}, {data.lastName}
          </p>
          <p
            className="text-center text-[#000] text-[16px] mb-4"
            style={{
              textShadow: "1px 1px 1px #fff", // Adjust the shadow size and color as needed
            }}
          >
            {data.departmentName}, {data.position}
          </p>
        </div>
      </div>
      <div className="mt-7 px-4">
        <p className="text-xs text-[#222] text-center">
          Дараах 3 төрлөөр {data.position} {data.firstName} -д өгнө үү.{" "}
        </p>
        <div className="my-6">
          <div className="flex justify-start">
            <div
              onClick={() => {
                setModal(1);
              }}
              className={`border border-[#C6C6C6] rounded-full w-[190px] h-[190px] flex flex-col items-center justify-center pb-3 ${
                shouldAnimate ? "fill-animation-bottom-left" : ""
              }`}
              style={{
                background: shouldAnimate ? "" : "white",
              }}
            >
              <img src="/img/icons/bIcon1.svg" alt="icon" />
              <p
                className={`text-[20px] font-semibold mt-2 ${
                  shouldAnimate ? "text-white-animation" : "text-[#636466]"
                }`}
              >
                START
              </p>
              <p
                className={`text-xs text-center w-[152px] ${
                  shouldAnimate ? "text-white-animation" : "text-[#A1A1A1]"
                }`}
              >
                Цаашид юуг{" "}
                <span className="font-semibold">хэрэгжүүлж эхэлбэл </span>
                зохистой тухай зөвлөнө үү.
              </p>
            </div>
          </div>

          <div className="flex justify-end">
            <div
              onClick={() => {
                setModal(3);
              }}
              className={`border border-[#C6C6C6] rounded-full w-[190px] h-[190px] flex flex-col items-center justify-center pb-3 ${
                shouldAnimate3 ? "fill-animation-bottom-top" : ""
              }`}
              style={{
                background: shouldAnimate3 ? "" : "white",
              }}
            >
              <img src="/img/icons/bIcon3.svg" alt="icon" />
              <p
                className={`text-[20px] font-semibold mt-2 ${
                  shouldAnimate3 ? "text-white-animation" : "text-[#636466]"
                }`}
              >
                STOP{" "}
              </p>
              <p
                className={`text-xs text-center w-[152px] ${
                  shouldAnimate3 ? "text-white-animation" : "text-[#A1A1A1]"
                }`}
              >
                Цаашид юуг{" "}
                <span className="font-semibold">хийхээ зогсоовол </span>
                зохистой тухай зөвлөнө үү.
              </p>
            </div>
          </div>
          <div className="flex justify-start">
            <div
              onClick={() => {
                setModal(2);
              }}
              className={`border border-[#C6C6C6] rounded-full w-[190px] h-[190px] flex flex-col items-center justify-center pb-3 ${
                shouldAnimate2 ? "fill-animation-bottom-top-left" : ""
              }`}
              style={{
                background: shouldAnimate2 ? "" : "white",
              }}
            >
              <img src="/img/icons/bIcon2.svg" alt="icon" />
              <p
                className={`text-[20px] font-semibold mt-2 ${
                  shouldAnimate2 ? "text-white-animation" : "text-[#636466]"
                }`}
              >
                Continue
              </p>
              <p
                className={`text-xs text-center w-[152px] ${
                  shouldAnimate2 ? "text-white-animation" : "text-[#A1A1A1]"
                }`}
              >
                Цаашид юуг{" "}
                <span className="font-semibold">
                  үргэлжлүүлэн хэрэгжүүлвэл{" "}
                </span>
                зохистой тухай зөвлөнө үү.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mx-6 mb-6">
          {btn && (
            <button
              onClick={() => {
                setPopUp(true);
              }}
              className="px-6  py-2  w-[300px]  text-sm text-white font-semibold bg-[#222] rounded-lg"
            >
              илгээх
            </button>
          )}
        </div>
      </div>
      {modal === 1 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div className="flex items-center gap-2">
              <img
                className="h-[48px] w-[48px]"
                src="/img/icons/bIcon1.svg"
                alt="icon"
              />
              <p className="font-semibold">Start</p>
            </div>
            <p className="text-xs opacity-70 w-[325px] mt-2">
              Таны өгсөн Feed-forward тухайн ажилтны ажлын бүтээмж, сэтгэлзүйд
              шууд нөлөөлөх боломжтой тул та эелдгээр зохистой, хэрэгцээтэй
              зөвлөмжийг өгнө үү.
            </p>
            {startCheck === 1 ? (
              <div className="mt-4">
                <div
                  onClick={() => {
                    setModal2(1);
                    setModal(0);
                  }}
                  className="flex items-start gap-2"
                >
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 1 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 1
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 1 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Та дараах өгүүлбэрийг нөхнө үү.
                      </p>

                      <p className="mt-2 text-xs text-[#222] opacity-70">
                        <span className="text-[#7278ff] opacity-100">
                          {data.firstname}
                        </span>{" "}
                        та цаашид
                        <span className="underline-gradient  font-semibold opacity-100">
                          {" "}
                          {start1}{" "}
                        </span>
                        эхэлвэл
                        <span className="underline-gradient  font-semibold opacity-100">
                          {" "}
                          {start2}{" "}
                        </span>
                        чадна.
                      </p>
                      <p className="text-xs text-[#222] opacity-70 my-3">
                        Би таныг чадна гэдэгт итгэлтэй байна
                        <span role="img" aria-label="smiley face">
                          {" "}
                          🤗
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setStartCheck(2);
                  }}
                  className="flex items-start gap-2 mt-4"
                >
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 2 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 2
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 2 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Feed-forward өгөх
                      </p>

                      <textarea
                        id="feedback"
                        name="feedback"
                        rows="3"
                        value={startWrite}
                        className="mt-1 block w-full text-[12px] h-[100px]"
                        placeholder="Энд бичнэ үү."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <div className="flex items-start gap-2">
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 2 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 2
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 2 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Feed-forward өгөх
                      </p>

                      <textarea
                        id="feedback"
                        name="feedback"
                        onChange={(e) => {
                          setStartWrite(e.target.value);
                        }}
                        rows="3"
                        className="mt-1 block w-full text-[12px] h-[100px]"
                        placeholder="Энд бичнэ үү."
                        value={startWrite}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {startWrite !== "" ? (
                  <button
                    onClick={() => {
                      setModal(0);
                    }}
                    className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                  >
                    үргэлжлүүлэх
                  </button>
                ) : (
                  <div
                    onClick={() => {
                      setStartCheck(1);
                    }}
                    className="flex items-start gap-2 mt-4"
                  >
                    <div
                      className={`h-4 w-4 rounded-full border-2 mt-2 ${
                        startCheck === 1 ? "border-blue-600" : "border-gray-300"
                      }`}
                      onClick={() => {
                        setStartCheck(1);
                      }}
                    />
                    <div>
                      <div
                        className={`w-[300px] border px-2 py-2 rounded-xl ${
                          startCheck === 1
                            ? "border-[#0752DF]"
                            : "border-[#f2f2f2] "
                        }`}
                      >
                        <p
                          className={`text-xs ${
                            startCheck === 1
                              ? "text-[#0752DF]"
                              : "text-[#A4A4A4]"
                          }`}
                        >
                          Та дараах өгүүлбэрийг нөхнө үү.
                        </p>

                        <p className="mt-2 text-xs text-[#222] opacity-70">
                          <span className="text-[#7278ff] opacity-100">
                            {data.firstname}
                          </span>{" "}
                          та цаашид
                          <span className="underline-gradient  font-semibold opacity-100">
                            {" "}
                            хэрэгжүүлж эхлэх зүйл{" "}
                          </span>
                          эхэлвэл
                          <span className="underline-gradient  font-semibold opacity-100">
                            {" "}
                            гарах үр дүн{" "}
                          </span>
                          чадна.
                        </p>
                        <p className="text-xs text-[#222] opacity-70 my-3">
                          Би таныг чадна гэдэгт итгэлтэй байна
                          <span role="img" aria-label="smiley face">
                            {" "}
                            🤗
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {modal === 2 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div className="flex items-center gap-2">
              <img
                className="h-[48px] w-[48px]"
                src="/img/icons/bIcon2.svg"
                alt="icon"
              />
              <p className="font-semibold">Continue</p>
            </div>
            <p className="text-xs opacity-70 w-[325px] mt-2">
              Тухайн ажилтны одоо хийж байгаа бүтэмжтэй, таатай хандлагыг
              урамшуулж цаашдаа энэхүү хандлагаа тогтоон барихад туслах feedbaсk
              бичнэ үү.
            </p>
            {startCheck === 1 ? (
              <div className="mt-4">
                <div
                  onClick={() => {
                    setModal2(2);
                    setModal(0);
                  }}
                  className="flex items-start gap-2"
                >
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 1 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 1
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 1 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Та дараах өгүүлбэрийг нөхнө үү.
                      </p>

                      <p className="mt-2 text-xs text-[#222] opacity-70">
                        <span className="text-[#7278ff] opacity-100">
                          {data.firstname}
                        </span>{" "}
                        та цаашид
                        <span className="underline-gradient  font-semibold opacity-100">
                          {" "}
                          {continue1}{" "}
                        </span>
                        идэвхитэй үргэлжлүүлвэл
                        <span className="underline-gradient  font-semibold opacity-100">
                          {" "}
                          {continue2}{" "}
                        </span>
                        чадна. Keep going!
                        <span role="img" aria-label="smiley face">
                          {" "}
                          ✨
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setStartCheck(2);
                  }}
                  className="flex items-start gap-2 mt-4"
                >
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 2 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 2
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 2 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Feed-forward өгөх
                      </p>

                      <textarea
                        id="feedback"
                        name="feedback"
                        rows="3"
                        className="mt-1 block w-full text-[12px] h-[100px]"
                        placeholder="Энд бичнэ үү."
                        value={continueWrite}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <div className="flex items-start gap-2">
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 2 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 2
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 2 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Feed-forward өгөх
                      </p>

                      <textarea
                        id="feedback"
                        name="feedback"
                        onChange={(e) => {
                          setContinueWrite(e.target.value);
                        }}
                        rows="3"
                        className="mt-1 block w-full text-[12px] h-[100px]"
                        placeholder="Энд бичнэ үү."
                        value={continueWrite}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {continueWrite !== "" ? (
                  <button
                    onClick={() => {
                      setModal(0);
                    }}
                    className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                  >
                    үргэлжлүүлэх
                  </button>
                ) : (
                  <div
                    onClick={() => {
                      setStartCheck(1);
                    }}
                    className="flex items-start gap-2 mt-4"
                  >
                    <div
                      className={`h-4 w-4 rounded-full border-2 mt-2 ${
                        startCheck === 1 ? "border-blue-600" : "border-gray-300"
                      }`}
                      onClick={() => {
                        setStartCheck(1);
                      }}
                    />
                    <div>
                      <div
                        className={`w-[300px] border px-2 py-2 rounded-xl ${
                          startCheck === 1
                            ? "border-[#0752DF]"
                            : "border-[#f2f2f2] "
                        }`}
                      >
                        <p
                          className={`text-xs ${
                            startCheck === 1
                              ? "text-[#0752DF]"
                              : "text-[#A4A4A4]"
                          }`}
                        >
                          Та дараах өгүүлбэрийг нөхнө үү.
                        </p>

                        <p className="mt-2 text-xs text-[#222] opacity-70">
                          <span className="text-[#7278ff] opacity-100">
                            {data.firstname}
                          </span>{" "}
                          та цаашид
                          <span className="underline-gradient  font-semibold opacity-100">
                            {" "}
                            үргэлжлүүлэх зүйл{" "}
                          </span>
                          идэвхитэй үргэлжлүүлвэл
                          <span className="underline-gradient  font-semibold opacity-100">
                            {" "}
                            гарах үр дүн{" "}
                          </span>
                          чадна. Keep going!
                          <span role="img" aria-label="smiley face">
                            {" "}
                            ✨
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {modal === 3 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div className="flex items-center gap-2">
              <img
                className="h-[48px] w-[48px]"
                src="/img/icons/bIcon3.svg"
                alt="icon"
              />
              <p className="font-semibold">STOP</p>
            </div>
            <p className="text-xs opacity-70 w-[325px] mt-2">
              Тухайн ажилтны одоо гаргаж буй таагүй, цухалдуулам хандлага,
              үйлдлийг зогсооход чиглэсэн Feed-forward өгөх ба ингэснээрээ ямар
              үр дүнд хүрж болохыг эелдгээр илэрхийлнэ үү.
            </p>
            {startCheck === 1 ? (
              <div className="mt-4">
                <div
                  onClick={() => {
                    setModal2(3);
                    setModal(0);
                  }}
                  className="flex items-start gap-2"
                >
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 1 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 1
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 1 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Та дараах өгүүлбэрийг нөхнө үү.
                      </p>

                      <p className="mt-2 text-xs text-[#222] opacity-70">
                        <span className="text-[#7278ff] opacity-100">
                          {data.firstname}
                        </span>{" "}
                        та цаашид{" "}
                        <span className="underline-gradient  font-semibold opacity-100">
                          {stop1}
                        </span>{" "}
                        больж чадвал{" "}
                        <span className="underline-gradient  font-semibold opacity-100">
                          {stop2}
                        </span>{" "}
                        чадна. Амжилт!
                        <span role="img" aria-label="smiley face">
                          {" "}
                          😎
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setStartCheck(2);
                  }}
                  className="flex items-start gap-2 mt-4"
                >
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 2 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 2
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 2 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Feed-forward өгөх
                      </p>

                      <textarea
                        id="feedback"
                        name="feedback"
                        rows="3"
                        className="mt-1 block w-full text-[12px] h-[100px]"
                        placeholder="Энд бичнэ үү."
                        value={stopWrite}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <div className="flex items-start gap-2">
                  <div
                    className={`h-4 w-4 rounded-full border-2 mt-2 ${
                      startCheck === 2 ? "border-blue-600" : "border-gray-300"
                    }`}
                  />
                  <div>
                    <div
                      className={`w-[300px] border px-2 py-2 rounded-xl ${
                        startCheck === 2
                          ? "border-[#0752DF]"
                          : "border-[#f2f2f2] "
                      }`}
                    >
                      <p
                        className={`text-xs ${
                          startCheck === 2 ? "text-[#0752DF]" : "text-[#A4A4A4]"
                        }`}
                      >
                        Feed-forward өгөх
                      </p>

                      <textarea
                        id="feedback"
                        name="feedback"
                        onChange={(e) => {
                          setStopWrite(e.target.value);
                        }}
                        rows="3"
                        className="mt-1 block w-full text-[12px] h-[100px]"
                        placeholder="Энд бичнэ үү."
                        value={stopWrite}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {stopWrite !== "" ? (
                  <button
                    onClick={() => {
                      setModal(0);
                    }}
                    className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                  >
                    үргэлжлүүлэх
                  </button>
                ) : (
                  <div
                    onClick={() => {
                      setStartCheck(1);
                    }}
                    className="flex items-start gap-2 mt-4"
                  >
                    <div
                      className={`h-4 w-4 rounded-full border-2 mt-2 ${
                        startCheck === 1 ? "border-blue-600" : "border-gray-300"
                      }`}
                      onClick={() => {
                        setStartCheck(1);
                      }}
                    />
                    <div>
                      <div
                        className={`w-[300px] border px-2 py-2 rounded-xl ${
                          startCheck === 1
                            ? "border-[#0752DF]"
                            : "border-[#f2f2f2] "
                        }`}
                      >
                        <p
                          className={`text-xs ${
                            startCheck === 1
                              ? "text-[#0752DF]"
                              : "text-[#A4A4A4]"
                          }`}
                        >
                          Та дараах өгүүлбэрийг нөхнө үү.
                        </p>

                        <p className="mt-2 text-xs text-[#222] opacity-70">
                          <span className="text-[#7278ff] opacity-100">
                            {data.firstname}
                          </span>{" "}
                          та цаашид
                          <span className="underline-gradient  font-semibold opacity-100">
                            {" "}
                            зогсоох зүйл
                          </span>{" "}
                          больж чадвал
                          <span className="underline-gradient  font-semibold opacity-100">
                            {" "}
                            гарах үр дүн
                          </span>{" "}
                          чадна. Амжилт!
                          <span role="img" aria-label="smiley face">
                            {" "}
                            😎
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {modal2 !== 0 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div>
              <div className="flex flex-col items-center">
                <div
                  className={`w-[300px] border px-2 py-2 rounded-xl border-[#F2F2F2]`}
                >
                  <p className="mt-2 text-xs text-[#222] opacity-70">
                    <span className="text-[#7278ff] opacity-100">
                      {" "}
                      {data.firstName}
                    </span>{" "}
                    та цаашид{" "}
                    <input
                      type="text"
                      onFocus={() => {
                        setStartOngGoing(1);
                      }}
                      value={placed}
                      placeholder={
                        modal2 === 1 ? start1 : modal2 === 2 ? continue1 : stop1
                      }
                      onChange={(e) => {
                        setPlaced(e.target.value);
                        const newValue = e.target.value;
                        if (modal2 === 1) {
                          setStart1(newValue);
                        } else if (modal2 === 2) {
                          setContinue1(newValue);
                        } else {
                          setStop1(newValue);
                        }
                      }}
                      className="p-0 text-xs underline-gradient bg-none w-auto"
                    />
                    {modal2 === 1
                      ? " эхэлвэл "
                      : modal2 === 2
                      ? " идэвхитэй үргэлжлүүлвэл "
                      : modal2 === 3
                      ? " больж чадвал "
                      : ""}
                    <input
                      type="text"
                      onFocus={() => {
                        setStartOngGoing(2);
                      }}
                      value={placed2}
                      placeholder={
                        modal2 === 1 ? start2 : modal2 === 2 ? continue2 : stop2
                      }
                      onChange={(e) => {
                        setPlaced2(e.target.value);
                        const newValue = e.target.value;
                        if (modal2 === 1) {
                          setStart2(newValue);
                        } else if (modal2 === 2) {
                          setContinue2(newValue);
                        } else {
                          setStop2(newValue);
                        }
                      }}
                      className="p-0 text-xs underline-gradient bg-none w-auto"
                    />
                    {modal2 === 1
                      ? " чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗"
                      : modal2 === 2
                      ? " чадна. Keep going!✨ "
                      : modal2 === 3
                      ? " чадна. Амжилт!😎"
                      : ""}
                  </p>
                </div>
              </div>
              <p className="text-xs mt-6 px-3 mb-2">
                Эдгээрээс сонгох болон өөрийн бодлоо бичгээр буулгах боломжтой.
              </p>
              {startOnGoing === 1 ? (
                <div className="px-3">
                  <p
                    onClick={() => {
                      if (modal2 === 1) {
                        setStart1("цаг баримтлаж");
                        setStartOngGoing(2);
                      } else if (modal2 === 2) {
                        setContinue1("ажилдаа өөриймсөг ханддагаа");
                        setStartOngGoing(2);
                      } else if (modal2 === 3) {
                        setStop1("хоцордгоо");
                        setStartOngGoing(2);
                      }
                    }}
                    className={`py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center`}
                  >
                    {modal2 === 1
                      ? "цаг баримтлаж"
                      : modal2 === 2
                      ? "ажилдаа өөриймсөг ханддагаа"
                      : "хоцордгоо"}
                  </p>
                  <p
                    onClick={() => {
                      if (modal2 === 1) {
                        setStart1("санаагаа чөлөөтэй илэрхийлж");
                        setStartOngGoing(2);
                      } else if (modal2 === 2) {
                        setContinue1("бусдад үлгэр дуурайлал үзүүлдгээ");
                        setStartOngGoing(2);
                      } else if (modal2 === 3) {
                        setStop1("аливааг хойш тавихаа");
                        setStartOngGoing(2);
                      }
                    }}
                    className={`py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center`}
                  >
                    {modal2 === 1
                      ? "санаагаа чөлөөтэй илэрхийлж"
                      : modal2 === 2
                      ? "бусдад үлгэр дуурайлал үзүүлдгээ"
                      : "аливааг хойш тавихаа"}
                  </p>
                  <p
                    onClick={() => {
                      if (modal2 === 1) {
                        setStart1("улам нээлттэй болж");
                        setStartOngGoing(2);
                      } else if (modal2 === 2) {
                        setContinue1("итгэл даахуйц, найдвартай байдгаа");
                        setStartOngGoing(2);
                      } else if (modal2 === 3) {
                        setStop1("өөрийгөө хэт өндрөөр үнэлэхээ");
                        setStartOngGoing(2);
                      }
                    }}
                    className={`py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center`}
                  >
                    {modal2 === 1
                      ? "улам нээлттэй болж"
                      : modal2 === 2
                      ? "итгэл даахуйц, найдвартай байдгаа"
                      : "өөрийгөө хэт өндрөөр үнэлэхээ"}
                  </p>
                  <p
                    onClick={() => {
                      if (modal2 === 1) {
                        setStart1("бүтээмж өндөртэй ажилтан болж");
                        setStartOngGoing(2);
                      } else if (modal2 === 2) {
                        setContinue1("шинийг санаачилдгаа");
                        setStartOngGoing(2);
                      } else if (modal2 === 3) {
                        setStop1("өөрийгөө дутуу үнэлэхээ");
                        setStartOngGoing(2);
                      }
                    }}
                    className={`py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center`}
                  >
                    {modal2 === 1
                      ? "бүтээмж өндөртэй ажилтан болж"
                      : modal2 === 2
                      ? "шинийг санаачилдгаа"
                      : "өөрийгөө дутуу үнэлэхээ"}
                  </p>
                </div>
              ) : (
                <div className="px-3">
                  <p
                    onClick={() => {
                      if (modal2 === 2) {
                        setContinue2("амжилтад хүрж");
                      } else if (modal2 === 1) {
                        setStart2("төсөлд хувь нэмэр оруулж");
                      } else if (modal2 === 3) {
                        setStop2("амжилтад хүрж");
                      }
                    }}
                    className="py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center"
                  >
                    {modal2 === 1
                      ? "төсөлд хувь нэмэр оруулж"
                      : "амжилтад хүрж"}
                  </p>
                  <p
                    onClick={() => {
                      if (modal2 === 2) {
                        setContinue2("төсөл амжилттай хэрэгжиж");
                      } else if (modal2 === 1) {
                        setStart2("төсөл амжилттай хэрэгжиж");
                      } else if (modal2 === 3) {
                        setStop2("төсөл амжилттай хэрэгжиж");
                      }
                    }}
                    className="py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center"
                  >
                    {modal2 === 1
                      ? "төсөл амжилттай хэрэгжиж"
                      : "төсөл амжилттай хэрэгжиж"}
                  </p>
                  <p
                    onClick={() => {
                      if (modal2 === 2) {
                        setContinue2("хамт олонтойгоо дотносож");
                      } else if (modal2 === 1) {
                        setStart2("хамт олонтойгоо дотносож");
                      } else if (modal2 === 3) {
                        setStop2("хамт олонтойгоо дотносож");
                      }
                    }}
                    className="py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center"
                  >
                    {modal2 === 1
                      ? "хамт олонтойгоо дотносож"
                      : "хамт олонтойгоо дотносож"}
                  </p>
                  <p
                    onClick={() => {
                      if (modal2 === 2) {
                        setContinue2("бүтээмж өндөртэй ажилтан болж");
                      } else if (modal2 === 1) {
                        setStart2("бүтээмж өндөртэй ажилтан болж");
                      } else if (modal2 === 3) {
                        setStop2("бүтээмж өндөртэй ажилтан болж");
                      }
                    }}
                    className="py-1 px-1 rounded-lg border border-[#A7A7A7] text-xs text-[#000] opacity-70 mb-3 text-center"
                  >
                    {modal2 === 1
                      ? "бүтээмж өндөртэй ажилтан болж"
                      : "бүтээмж өндөртэй ажилтан болж"}
                  </p>
                </div>
              )}
              <div
                onClick={() => {
                  setIsHidden(!isHidden);
                }}
                className="py-1 flex items-center gap-1 ps-4"
              >
                <input
                  checked={isHidden}
                  onChange={() => {
                    setIsHidden(!isHidden);
                  }}
                  className="w-[16px] rounded"
                  type="checkbox"
                />

                <p className="text-[#222] text-[13px]">Нэрээ нууцлах</p>
              </div>

              {modal2 === 1 ? (
                start1 !== "хэрэгжүүлж эхлэх зүйл" &&
                start2 !== "гарах үр дүн" ? (
                  <button
                    onClick={handleContinue}
                    className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                  >
                    үргэлжлүүлэх
                  </button>
                ) : (
                  ""
                )
              ) : modal2 === 2 ? (
                continue1 !== "үргэлжлүүлэх зүйл" &&
                continue2 !== "гарах үр дүн" ? (
                  <button
                    onClick={handleContinue}
                    className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                  >
                    үргэлжлүүлэх
                  </button>
                ) : (
                  ""
                )
              ) : modal2 === 3 ? (
                stop1 !== "зогсоох зүйл" && stop2 !== "гарах үр дүн" ? (
                  <button
                    onClick={handleContinue}
                    className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                  >
                    үргэлжлүүлэх
                  </button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {popUp && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-4 px-4">
            <p className="text-center text-[#444] text-sm font-semibold mb-1">
              Та нэрээ нууцлан Feed-forward илгээх гэж байна.
            </p>
            <p className="text-[#6B7280] text-xs text-center mb-4">
              Илгээх үү?
            </p>
            <div
              onClick={() => {
                setIsHidden(!isHidden);
              }}
              className="py-1 flex items-center gap-1 mb-4"
            >
              <input
                checked={isHidden}
                onChange={() => {
                  setIsHidden(!isHidden);
                }}
                className="w-[16px] rounded"
                type="checkbox"
              />

              <p className="text-[#222] text-[13px]">Нэрээ нууцлах</p>
            </div>
            <button
              className="w-full py-2 flex items-center justify-center text-white bg-[#2D2D2D] rounded-xl"
              onClick={sendBackend}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner">
                  <FaSpinner className="spin-animation" />
                </span>
              ) : (
                "Илгээх"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhFeedback;
