import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { DataProvider, DataContext } from "./DataContext";
import Search from "./pages/Search";
import NotFound from "./NotFound";
import "./index.css";
import PhEndingPage from "./pages/mobile/PhEndingPage";
import EmpProfileSection from "./pages/EmpProfileSection";
import EndingPage from "./pages/Desktop/EndingPage";
import Home from "./pages/Home";
import PhInboxPage from "./pages/mobile/PhInboxPage";
import { getCookie } from "./utils/cookie";

const AppRoutes = () => {
  const navigate = useNavigate();
  const { setData } = React.useContext(DataContext);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    const refreshFlag = sessionStorage.getItem("refreshed");

    if (refreshFlag) {
      console.log("Page was refreshed");
      const id = getCookie("userId");
      if (id) {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `https://metacogserver.azurewebsites.net/v1/feedback/mcsi/${id}`
            );
            const result = await response.json();
            setData(result);
            navigate(`/mcsi/${id}`);
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setInitialized(true);
          }
        };

        fetchData();
      } else {
        navigate("/mcsi/search");
        setInitialized(true);
      }
    } else {
      console.log("Initial load");
      sessionStorage.setItem("refreshed", "true");
      setInitialized(true);
    }

    return () => {
      sessionStorage.removeItem("refreshed");
    };
  }, [navigate, setData]);

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/mcsi/:id" element={<Home />} />
      <Route path="/mcsi/search" element={<Search />} />
      <Route path="/mcsi/employee/:id" element={<EmpProfileSection />} />
      <Route path="/mcsi/ph/sended" element={<PhEndingPage />} />
      <Route path="/mcsi/sended" element={<EndingPage />} />
      <Route path="/mcsi/ph/inbox" element={<PhInboxPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function App() {
  return (
    <div className="App">
      <DataProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </DataProvider>
    </div>
  );
}

export default App;
