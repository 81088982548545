import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookie";
import axios from "axios";

const PhInboxPage = () => {
  const [selected, setSelected] = useState(1);
  const [start, setStart] = useState(null);
  const [cont, setCont] = useState(null);
  const [stop, setStop] = useState(null);
  const navigate = useNavigate();
  const id = getCookie("userId");
  const [data, setData] = useState(null);
  const [height, setHeight] = useState(window.innerHeight - 250);

  useEffect(() => {
    axios
      .get(`https://metacogserver.azurewebsites.net/v1/feedback/mcsi/${id}`)
      .then((data) => {
        setData(data.data.employee);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  useEffect(() => {
    axios
      .get(
        `https://metacogserver.azurewebsites.net/v1/feedback-answer/own?empId=${id}`
      )
      .then((response) => {
        const data = response.data;

        // Helper function to sort messages by createdDate in descending order
        const sortByDateDesc = (a, b) => {
          const dateA = new Date(a.createdDate);
          const dateB = new Date(b.createdDate);
          return dateB - dateA;
        };

        // Filter and sort data by type and createdDate
        const startMessages = data
          .filter((item) => item.type === "START")
          .sort(sortByDateDesc);
        const continueMessages = data
          .filter((item) => item.type === "CONTINUE")
          .sort(sortByDateDesc);
        const stopMessages = data
          .filter((item) => item.type === "STOP")
          .sort(sortByDateDesc);

        // Set the state with the sorted data
        setStart(startMessages);
        setCont(continueMessages);
        setStop(stopMessages);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, [id]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 250);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (start === null || cont === null || stop === null || data === null) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="relative bg-[#F7F9FB] min-h-screen ">
      <div className="px-8">
        <p className="text-[28px] text-center pt-5 font-bold text-[#363636] ">
          Танд ирсэн Feed-forward .
        </p>
        <div className="flex items-start justify-between mt-2 md:px-60 sm:px-0 px-0">
          <div
            onClick={() => {
              setSelected(1);
            }}
            className="flex flex-col items-center justify-center"
          >
            <img
              className={`${
                selected === 1 ? "h-[48px] w-[48px]" : "h-[40px] w-[40px]"
              }`}
              src="/img/icons/bIcon1.svg"
              alt="icon"
            />
            {selected === 1 && (
              <p className="font-semibold text-sm text-center">Start</p>
            )}
          </div>
          <div
            onClick={() => {
              setSelected(2);
            }}
            className="flex flex-col items-center justify-center"
          >
            <img
              className={`${
                selected === 2 ? "h-[48px] w-[48px]" : "h-[40px] w-[40px]"
              }`}
              src="/img/icons/bIcon2.svg"
              alt="icon"
            />
            {selected === 2 && (
              <p className="font-semibold text-sm text-center">Continue</p>
            )}
          </div>
          <div
            onClick={() => {
              setSelected(3);
            }}
            className="flex flex-col items-center justify-center"
          >
            <img
              className={`${
                selected === 3 ? "h-[48px] w-[48px]" : "h-[40px] w-[40px]"
              }`}
              src="/img/icons/bIcon3.svg"
              alt="icon"
            />
            {selected === 3 && (
              <p className="font-semibold text-sm text-center">Stop</p>
            )}
          </div>
        </div>
        <div className="flex  justify-center">
          {selected === 1 ? (
            <div
              className="mt-5 bg-[#fff] rounded-xl overflow-y-auto w-full sm:w-full md:w-4/6  px-6 py-4 shadow-lg"
              style={{ height: `${height}px` }}
            >
              {start.length !== 0 ? (
                <div>
                  {start.map((items) => {
                    return (
                      <div className="bg-[#F6F9FF]  p-2 border border-[#0752DF] rounded-lg mb-4">
                        {!items.hide ? (
                          <p className="text-[11px] text-[#222] font-semibold">
                            {items.firstName}{" "}
                            <span className="font-medium">-с</span>
                          </p>
                        ) : (
                          ""
                        )}
                        <p className="text-xs text-[#222] opacity-70">
                          {data.firstName} <span>{items.text}</span>
                        </p>
                        <p className="text-[10px] text-[#222] opacity-50 text-end">
                          {items.createdDate.substring(0, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="mt-4 ms-4">Таньд Feed-forward ирээгүй байна</p>
              )}
            </div>
          ) : selected === 2 ? (
            <div
              className="mt-5 bg-[#fff] rounded-xl overflow-y-auto w-full sm:w-full md:w-4/6  px-6 py-4 shadow-lg"
              style={{ height: `${height}px` }}
            >
              {cont.length !== 0 ? (
                <div>
                  {cont.map((items) => {
                    return (
                      <div className="bg-[#F6F9FF]  p-2 border border-[#0752DF] rounded-lg mb-4">
                        {!items.hide ? (
                          <p className="text-[11px] text-[#222] font-semibold">
                            {items.firstName}{" "}
                            <span className="font-medium">-с</span>
                          </p>
                        ) : (
                          ""
                        )}
                        <p className="text-xs text-[#222] opacity-70">
                          {data.firstName} <span>{items.text}</span>
                        </p>
                        <p className="text-[10px] text-[#222] opacity-50 text-end">
                          {items.createdDate.substring(0, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="mt-4 ms-4">Таньд Feed-forward ирээгүй байна</p>
              )}
            </div>
          ) : selected === 3 ? (
            <div
              className="mt-5 bg-[#fff] rounded-xl overflow-y-auto w-full sm:w-full md:w-4/6  px-6 py-4 shadow-lg"
              style={{ height: `${height}px` }}
            >
              {stop.length !== 0 ? (
                <div>
                  {stop.map((items) => {
                    return (
                      <div
                        className="bg-[#F6F9FF] p-2 border border-[#0752DF] rounded-lg mb-4"
                        key={items.id}
                      >
                        {!items.hide ? (
                          <p className="text-[11px] text-[#222] font-semibold">
                            {items.firstName}{" "}
                            <span className="font-medium">-с</span>
                          </p>
                        ) : (
                          ""
                        )}
                        <p className="text-xs text-[#222] opacity-70">
                          {data.firstName} <span>{items.text}</span>
                        </p>
                        <p className="text-[10px] text-[#222] opacity-50 text-end">
                          {items.createdDate.substring(0, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="mt-4 ms-4">Таньд Feed-forward ирээгүй байна</p>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="fixed w-full bg-white bottom-0 flex justify-between">
        <div
          onClick={() => {
            navigate("/mcsi/search");
          }}
          className="flex items-center justify-center w-[50%] px-4 py-2"
        >
          <p className="text-lg ">Илгээх</p>
        </div>
        <div className="flex items-center justify-center w-[50%] border-b-4 border-[#F48D7E] px-4 py-2">
          <p className="text-lg font-semibold text-[#F48D7E]">Шалгах</p>
        </div>
      </div>
    </div>
  );
};

export default PhInboxPage;
