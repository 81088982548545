import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookie";

const EndingPage = () => {
  // const [query, setQuery] = useState("");
  const navigate = useNavigate();
  // const [focused, setFocused] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const Cid = getCookie("userId");
    setUserId(Cid);
  }, []);

  // const handleChange = (e) => {
  //   setQuery(e.target.value);
  // };

  // const filteredEmployees = employees.filter(
  //   (emp) =>
  //     emp.firstname.toLowerCase().includes(query.toLowerCase()) ||
  //     emp.lastname.toLowerCase().includes(query.toLowerCase())
  // );

  return (
    <div
      style={{ backgroundImage: "url('/img/background.png')" }}
      className="w-screen min-h-screen bg-cover  z-10 absolute"
    >
      <div className="h-screen flex items-center  justify-center">
        <div className="flex flex-col items-center pb-40">
          <p className="text-[36px] font-semibold mb-4">
            Feed-forward амжилттай илгээгдлээ.
          </p>
          <img src="/img/ended.svg" alt="img" />
          <button
            onClick={() => {
              navigate(`/mcsi/${userId}`);
            }}
            className=" py-2 rounded-xl w-[200px] text-white bg-[#222] text-sm font-semibold mt-10"
          >
            Буцах
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndingPage;
