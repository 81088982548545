import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/cookie";

const PhEndingPage = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const Cid = getCookie("userId");
    setUserId(Cid);
  }, []);
  return (
    <div
      style={{ backgroundImage: "url('/img/background.png')" }}
      className="w-screen min-h-screen bg-cover  z-10 absolute"
    >
      <div className="relative min-h-screen flex items-center justify-center">
        <div className="h-full">
          <div className="mb-10 flex items-center flex-col justify-center">
            <p className="text-3xl font-bold px-3">
              Feed-forward амжилттай илгээгдлээ.
            </p>
            <img className="mt-6" src="/img/ended.svg" alt="img" />
          </div>
        </div>
        <div className="absolute bottom-0 text-center pb-8">
          <button
            onClick={() => {
              navigate(`/mcsi/${userId}`);
            }}
            className=" py-2 rounded-xl w-[250px] text-white bg-[#222] text-sm font-semibold"
          >
            Буцах
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhEndingPage;
